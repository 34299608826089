import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {getStoreInfo} from './getProduct';
import {IStoreInfo} from '@wix/wixstores-graphql-schema';

export class StoreInfoService {
  constructor(private readonly siteStore: SiteStore) {}

  public async fetchStoreInfo(): Promise<Partial<IStoreInfo>> {
    const {data} = await getStoreInfo(this.siteStore);
    return data.storeInfo;
  }
}
